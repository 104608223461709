import React, { lazy } from 'react';
import {dashboardMenu, demoPages, smsMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';

const LISTS = {
	LISTS: lazy(() => import('../pages/lists/ViewList')),
	EDIT_LIST: lazy(() => import('../pages/lists/Lists')),
};
const CUSTOMFIELDS = {
	CUSTOMFIELDS: lazy(() => import('../pages/lists/ViewCustomField'))	
};

// const CONTACTS = {
// 	CONTACTS: lazy(() => import('../pages/contacts/ViewList')),
// 	EDIT_CONTACTS: lazy(() => import('../pages/contacts/Lists')),
// 	ADD_CONTACT: lazy(() => import('../pages/contacts/AddContact')),
// };

const SENDERIDS = {
	SENDERIDS: lazy(() => import('../pages/senderids/ViewSenderids')),	
	APPLY_SENDERID: lazy(() => import('../pages/senderids/ApplySenderids')),
	EDIT_SENDERID:lazy(() => import('../pages/senderids/EditSenderids')),
};

const BROADCASTS = {
	BROADCASTS: lazy(() => import('../pages/broadcasts/ViewBroadcasts')),	
	// ADD_BROADCAST: lazy(() => import('../pages/broadcasts/AddBroadcast')) 	
};

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};



const apppages = [
	/**
	 * Pages
	 */

	/**
	 * Lists Pages
	 */
	 {
		path: smsMenu.lists.subMenu.listAll.path,
		element: <LISTS.LISTS />,
		exact: true,
	},
	{
		path: `${smsMenu.lists.subMenu.listAll.path}/:id/edit`,
		element: <LISTS.EDIT_LIST />,
		exact: true,
	},
	{
		path: smsMenu.lists.subMenu.customFieldsAll.path,
		element: <CUSTOMFIELDS.CUSTOMFIELDS />,
		exact: true,
	},

	/**
	 * Broadcast Pages
	 */
	 {
		path: smsMenu.broadcasts.subMenu.broadcastsAll.path,
		element: <BROADCASTS.BROADCASTS />,
		exact: true,
	},
	/**
	 * SenderID Pages
	 */
	 {
		path: smsMenu.senderid.subMenu.senderidsAll.path,
		element: <SENDERIDS.SENDERIDS />,
		exact: true,
	},{
		path: smsMenu.senderid.subMenu.applySenderid.path,
		element: <SENDERIDS.APPLY_SENDERID />,
		exact: true,
	},{
		path: `${smsMenu.senderid.subMenu.senderidsAll.path}/:id/edit`,
		element: <SENDERIDS.EDIT_SENDERID />,
		exact: true,
	},
];

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},

	/** ************************************************** */

	/**
	 * Auth Page
	 */
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},
];

const contents = [...presentation, ...apppages];

export default contents;
