import { useContext } from 'react';
import ThemeContext from '../contexts/themeContext';

export default function useDarkMode() {
	const { darkModeStatus, setDarkModeStatus,userRow } = useContext(ThemeContext);

	const themeStatus = darkModeStatus ? 'dark' : 'light';
	// const baseUrl = 'http://smsplus9.local/';
	const baseUrl = 'https://azeem.dev.jt.hostingshouse.com/projects/smsplus-react/api/';

	

	return { themeStatus, darkModeStatus, setDarkModeStatus,userRow,baseUrl};
}
