import React, { useState, useContext } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import LogoLogin from '../../../components/LogoLogin';
import useDarkMode from '../../../hooks/useDarkMode';

import ThemeContext from '../../../contexts/themeContext';



// eslint-disable-next-line react/prop-types
const LoginHeader = () => {
	return (
		<>
			<div className='text-center h4 fw-bold mt-5'>Welcome to MumaraSMS</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>			
		</>
	);
};

const Login = () => {
	const navigate = useNavigate();
	const { userRow,baseUrl } = useDarkMode();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loginError, setLoginError] = useState(false);
	const { setUserRow } = useContext(ThemeContext);
	
	if(userRow.login_status===1){
		setUserRow({user_id:userRow.id, name: userRow.name, 'email': userRow.email, login_status: 1,mobile_no:userRow.mobile_no,first_name:userRow.first_name,token_create_date:userRow.token_create_date,refresh_token_salt:userRow.refresh_token_salt,api_token:userRow.api_token,role_id:userRow.role_id});		
		navigate('/');		
	}
	
	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	}
	const handlePassswordChange = (e) => {
		setPassword(e.target.value);
	}
	const handleOnClick = async () => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ 'email': email,'password':password })
		};
		const res = await fetch(`${baseUrl}login`, requestOptions);
		const data = await res.json();
		
		if(data.login_status===1){
			setUserRow(data);
			setLoginError(false);
			setUserRow({user_id:data.id ,name: data.name, 'email': data.email, login_status: 1,mobile_no:data.mobile_no,first_name:data.first_name,token_create_date:data.token_create_date,refresh_token_salt:data.refresh_token_salt,api_token:data.api_token,role_id:data.role_id});					
			navigate('/');
		}else{							
			setLoginError(true);
			setUserRow({ name: "", 'email': "", login_status: 0 });			
		}
			
	}
	
	return (

		<PageWrapper
			title="Login"
			className="bg-success">
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-2'>
									<Link to='/' >
										<LogoLogin width={200} />
									</Link>
								</div>

								<LoginHeader />
								{loginError? <div className='text-center h4 text-muted mb-5'><p className="text-danger">incorrect Username/Password</p></div>:""}				
								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup
											id='userEmail'
											isFloating
											onChange={handleEmailChange}
											label='Email Address'>
											<Input autoComplete='username' name="email" />
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup
											id='login-password'
											isFloating
											onChange={handlePassswordChange}
											label='Password'>
											<Input
												type='password'
												autoComplete='password'
												name="password"

											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											color='success'
											className='w-100 py-3'
											onClick={handleOnClick}>
											Login
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className='link-dark text-decoration-none me-3'>
								Privacy policy
							</a>
							<a href='/' className="link-dark text-decoration-none">
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
